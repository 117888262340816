.buttonsContainer {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

.button {
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.button:hover {
  scale: 1.1;
}

.reportContainer {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: white;
  z-index: 11;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.reportHeader {
  padding-left: 3rem;
  padding-right: 3rem;
  position: sticky;
  background-color: white;
  top: 1rem;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  flex-wrap: wrap;
}

.reportHeader .controls {
  display: flex;
  gap: 0.5rem;
}

.reportTitleContainer {
  display: flex;
  gap: 1rem;
  cursor: pointer;
  align-items: center;
}

.reportTitleContainer .title {
  font-size: 1.4rem;
  font-weight: 600;
}

.reportTitleContainer .backArrow {
  padding-top: 5px;
}

.reportBody {
  border: 10px solid #bfcbd1;
  height: calc(100% - 7rem);
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding: 1rem;
}

.reportForms {
  height: 100%;
  width: 100%;
}
