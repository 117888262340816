#woDetails {
  height: 100%;
  width: 100%;
  padding: 3rem;
  display: flex;
  flex-direction: column;
  overflow: auto;
  gap: 2rem;
}

.woContentHeader .pageTitle {
  font-size: 1.3rem;
}

.pageContentBlock {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.blockContent {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
}

.detailsRow {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: space-between;
}

.detailsRow .formsBody {
  flex-basis: 40%;
  width: unset;
  flex-grow: 1;
  flex-direction: column;
  justify-content: end;
}

#woDetails .formsInput {
  min-width: unset;
  flex-basis: 60%;
}
#woDetails .formsInput .inputContainer input {
  background: #0000000a;
}
