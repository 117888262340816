.newInterconnectionOptions {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  flex-wrap: wrap;
}
.newInterconnectionOption {
  flex-basis: 48%;
  flex-grow: 1;
  min-height: 100%;
  border-radius: 12px;
  border: 1px solid #e4e4e7;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  transition: 0.2s ease-in-out;
}

.newInterconnectionOption:hover {
  border-color: black;
  cursor: pointer;
}

.newInterconnectionOption .headerImage {
  height: 5rem;
  width: 5rem;
  border-radius: 12px;
  border: 1px solid #e4e4e7;
  display: flex;
  align-items: center;
  justify-content: center;
}

.newInterconnectionOption .headerImage img {
  height: 30%;
  min-height: 1.5rem;
  object-fit: contain;
  aspect-ratio: 1/1;
}

.backButton:hover {
  border-color: black !important;
  background: white !important;
  color: black !important;
}

.newInterconnectionOption .body {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.newInterconnectionOption .title {
  font-size: 20px;
  font-weight: 600;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
.newInterconnectionOption .sub {
  font-size: 18px;
  font-weight: 400;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.uploadSheetModalBody {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  gap: 2rem;
}
.uploadSheetModalBody::-webkit-scrollbar {
  display: none;
}

.uploadSheetModalButtons {
  width: 100%;
  display: flex;
  width: 100%;
  position: sticky;
  bottom: 0;
}

.uploadSheetModalButtons .backButton:hover {
  border-color: black !important;
  background: white !important;
  color: black !important;
}

.drawers {
  height: 100%;
  width: 100%;
  display: flex;
  border-radius: 8px;
  flex-direction: column;
  border: 1.43px solid #d9d9d9;
  margin-bottom: 2rem;
}

.drawer {
  display: flex;
  flex-direction: column;
}

.drawer .head {
  cursor: pointer;
  height: 4rem;
  display: flex;
  align-items: center;
  background: #00000005;
  padding-left: 1rem;
  gap: 1rem;
  font-size: 1.5rem;
  font-weight: 500;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
.drawer:first-of-type {
  border-bottom: 1.43px solid #d9d9d9;
}

.drawer .body {
  overflow: hidden;
  display: none;
  padding: 1rem;
}
.drawer .body.open {
  display: flex;
  flex-direction: column;
  height: 25rem;
  border-top: 1.43px solid #d9d9d9;
}
