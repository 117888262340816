.opwellTableContainer {
  width: 100%;
  overflow: auto;
  height: 100%;
  flex: 1;
}
.opwellTableButtons {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
}
.stickyWorkaround {
  min-width: 100%;
  width: fit-content;
}
.opwellTableContainer::-webkit-scrollbar {
  display: none;
}
.stickySpacer {
  height: 1rem;
  width: 100%;
  flex-grow: 1;
  background-color: white;
  position: sticky;
  top: 0;
  z-index: 2;
}

.opwellTable {
  border-spacing: 0;
  padding-top: 0;
  width: 100%;
}
.opwellTableRow {
  height: 4rem;
  font-size: 1rem;
  text-align: left;
}
.opwellTableRow:hover {
  background-color: #f8f9fa;
}
.opwellTableRow.title {
  background: #f5f7f9;
  font-size: 1.1rem;
  font-weight: 600;
  text-align: left;
  position: sticky;
  top: 1rem;
  z-index: 2;
}
.opwellTableRow.title .opwellTableCell:first-of-type {
  border-top-left-radius: 12px !important;
}
.opwellTableRow.title .opwellTableCell:last-of-type {
  border-top-right-radius: 12px !important;
}

.opwellTableCell {
  border: 0.63px solid var(--Components-Table-Global-colorSplit, #0000000f);
  padding: 1rem;
}

.opwellTableCell.dataRow {
  cursor: pointer;
}

.opwellTableRow.loading {
  position: relative;
  background-color: white;
  cursor: progress;
  z-index: 10;
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

.opwellTableCellTitleContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
}
.opwellTableCellControlls {
  display: flex;
  gap: 1rem;
  align-items: center;
}
.opwellTableCellControll {
  cursor: pointer;
  display: flex;
  align-items: center;
}
.opwellTableCellControll.filter {
  position: relative;
}

.opwellTableCellFilterWrapper {
  background-color: white;
  min-height: 5rem;
  width: 20rem;
  border: 2px solid #0000000f;
  border-radius: 8px;
  box-shadow: 0px 2px 5px 0px #0000000d;
  cursor: default;
}

.opwellTableCellFilterContainer {
  background-color: white;
  min-height: 5rem;
  min-width: 20rem;
  border: 2px solid #0000000f;
  border-radius: 8px;
  box-shadow: 0px 2px 5px 0px #0000000d;
  cursor: default;
}
.opwellTableCellFilter {
  display: flex;
  /* text-align: center; */
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
}
.opwellTableCellFilter .inputContainer {
  display: flex;
}
.opwellTableCellFilter .inputContainer .customInput {
  height: unset;
  flex-grow: 1;
  width: unset;
}
.opwellTableCellFilter .inputContainer .select-container {
  flex-grow: 1;
}

.cleanFilterButton:hover {
  color: white !important;
  background-color: #ff4d4f !important;
  border-color: #ff4d4f !important;
}
.filterTitle {
  font-size: 1rem;
  font-weight: 500;
}
.filterButton.disabled {
  opacity: 0.5;
}
.opwellTableRow:last-of-type .opwellTableCell:first-of-type {
  border-bottom-left-radius: 12px !important;
}
.opwellTableRow:last-of-type .opwellTableCell:last-of-type {
  border-bottom-right-radius: 12px !important;
}
.opwellTablePagination {
  background-color: white;
  position: sticky;
  bottom: 0;
  left: 0;
  padding-top: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: end;
  z-index: 2;
}
.opwellTablePaginationControlls {
  display: flex;
  width: 100%;
  gap: 2rem;
  align-items: center;
  justify-content: end;
  flex-wrap: wrap;
}
.pageSizeSelector {
  display: flex;
  align-items: center;
  min-width: 10rem;
  gap: 1rem;
  flex-grow: 1;
}
.pageSizeSelectorText {
  flex-shrink: 0;
}
.pageSelectorButton {
  aspect-ratio: 1/1;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 3px solid transparent;

  transition: all 0.2s ease-in-out;
}
.pageSelectorButton:hover {
  font-weight: 600;
  border-radius: 8px;
}

.pageSelectorButton.selected {
  border: 3px solid #1677ff;
  border-radius: 8px;
  color: #1677ff;
  font-weight: 600;
}

.pageSelectorArrow {
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  height: 1rem;
}
.pageSelector {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
}

.tableTipContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
}

.tableTip {
  border: 1.25px solid;
  background: #00000005;
  border-color: #d9d9d9;
  color: #000000e0;
  border-radius: 8px;
  padding: 0.2rem;
  font-size: 0.9rem;
  width: 70%;
  font-weight: 400;
  text-align: center;
}

.tableTip.ongoing {
  background: #e6f4ff;
  border-color: #91caff;
  color: #1677ff;
}
.tableTip.success {
  background: #f6ffed;
  border-color: #b7eb8f;
  color: #52c41a;
}
.tableTip.alert {
  background: #fffbe6;
  border-color: #ffe58f;
  color: #faad14;
}
.tableTip.fail {
  background: #fff2f0;
  border-color: #ffccc7;
  color: #ff4d4f;
}
