.gridContainer {
  max-height: 100%;
  width: 100%;
  padding: 2rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(19rem, 1fr));
  gap: 1rem;
  overflow: auto;
}

.gridItem {
  min-height: 16rem;
  border: 1.02px solid #e4e4e7;
  border-radius: 20px;
  display: flex;
  padding: 2rem;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  cursor: pointer;
}

.gridItem:hover {
  border: 1.02px solid black;
}

.gridItem.new {
  background: #f8f9fa;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.gridItem.new .gridItemPlus {
  height: 7rem;
  aspect-ratio: 1/1;
  background-color: white;
  border-radius: 100%;
  border: 1px solid #9fa0aa;
  display: flex;
  align-items: center;
  justify-content: center;
}

.gridItemPlus img {
  height: 50%;
  object-fit: contain;
}

.gridItem.new .gridItemText {
  font-size: 22px;
  font-weight: 600;
  line-height: 29.04px;
  letter-spacing: -0.02em;
  text-align: center;
  color: #686978;
}

.gridItemDescription {
  font-size: 1rem;
  font-weight: 400;
  line-height: 20.77px;
  letter-spacing: -0.01em;
  text-align: left;
}
.gridItemTitle {
  font-size: 19.82px;
  font-weight: 600;
  line-height: 26.16px;
  letter-spacing: -0.02em;
  text-align: left;
}

.growSlightly {
  transition: all 0.2s ease-in-out;
}
.growSlightly:hover {
  transform: scale(1.02);
}
