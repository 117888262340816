.manageCategoriesGrid {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 1rem;
}
.manageCategoriesGridItem {
  flex-basis: 40%;
  min-height: 20rem;
  max-width: 100%;
  flex-grow: 1;
  padding: 1rem;
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: start;
  justify-content: space-between;
  border: 1px solid #bbbbbb;
  border-radius: 12px;
}

.manageCategoriesGridTitle {
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 28.61px;
  text-align: left;
}
.manageCategoriesGridSubtitle {
  font-size: 1rem;
  font-weight: 400;
  line-height: 23.24px;
  text-align: left;
}
.manageCategoriesGridItemImg img {
  height: 5rem;
}
