.inputContainer {
  width: 100%;
  position: relative;
}
.inputIconContainer {
  position: absolute;
  height: 3rem;
  width: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.customInput {
  border: 2px solid #ebecee;
  background-color: white;
  border-radius: 8px;
  height: 3rem;
  width: 100%;
  padding: 0.5rem;
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 26.66px;
  letter-spacing: -0.02em;
  text-align: left;
}
.customInput.disabled {
  color: #969696;
  cursor: not-allowed !important;
  opacity: 80%;
}
