.instrumentsContent {
  height: 100%;
  width: 100%;
  padding: 2rem;
  padding-top: 3rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.instrumentsContentHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1rem;
}

.pageTitle {
  font-size: 1.7rem;
  font-weight: 600;
  text-align: left;
}

.pageButtons {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}
