.knowledgeItem {
  width: 100%;
  min-height: 8rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  border-bottom: 1px solid #c2c2c280;
  padding: 1rem;
  cursor: pointer;
}
.knowledgeItem:hover {
  border-radius: 12px;
  box-shadow: 0px 1px 4px 0px #e7e7e7;
  border-bottom: 1px solid transparent;
  background: #e4ebf0;
}

.knowledgeItem.selected {
  border-radius: 12px;
  background: #e4ebf0;
  box-shadow: 0px 1px 4px 0px #e7e7e7;
  border-bottom: 1px solid transparent;
}
.knowledgeItem .title {
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 25px;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 25rem;
}
.knowledgeItem .subtitle {
  font-size: 1.3rem;
  font-weight: 600;
  line-height: 27.58px;
  text-align: left;
  color: #686978;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 25rem;
}
.knowledgeItem .info {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  font-size: 1.3rem;
  font-weight: 300;
  line-height: 22px;
  text-align: left;
  flex-wrap: wrap;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 25rem;
}

.knowledgeInfos {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  font-size: 20px;
  font-weight: 400;
  line-height: 30.77px;
  letter-spacing: 0.01em;
  text-align: left;
}
.subjectContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
}
.subjectContainer.disabled {
  cursor: not-allowed;
}

.infoCircle {
  transition: all 0.2s ease-in;
}

.subjectContainer:hover .infoCircle {
  scale: 1.1;
  color: #1777ff;
}

.subjectAndUser {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
}

.pageContent #cancel:hover {
  color: white !important;
  background-color: #ff4d4f !important;
  border-color: #ff4d4f !important;
}

.nothingSelected {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}
.nothingSelectedText {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.nothingSelected .title {
  font-size: 2rem;
  font-weight: 600;
  line-height: 30.77px;
  letter-spacing: 0.01em;
  text-align: center;
}
.nothingSelected .subtitle {
  font-size: 20px;
  font-weight: 400;
  line-height: 30.77px;
  letter-spacing: 0.01em;
  text-align: center;
  color: #696969;
}
