.addKnowledgeButton {
  color: white !important;
  border: black !important;
}
.addKnowledgeButton:hover {
  color: white !important;
  border: black !important;
}

.addKnowledgeModalContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2rem;
  min-height: 20rem;
  max-height: 100%;
  flex-grow: 1;
}

.addKnowledgeTextArea::-webkit-scrollbar {
  display: none;
}
.addKnowledgeTextArea {
  height: 10rem;
  overflow: auto;
  resize: none;
  flex-grow: 1;
  min-height: 8rem;
  min-width: 100%;
  padding: 1rem;
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 2rem;
  border: 1px solid #d9d9d9;
  border-radius: 12px;
}
.addKnowledgeTextArea.md {
  background-color: #f6f6f6;
}

.addKnowledgeDescription {
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.addKnowledgeBigButton {
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 26.4px;
  letter-spacing: -0.01em;
}

.addKnowledgeLoading {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.addKnowledgeLoading .ant-spin-dot-holder {
  font-size: 10rem;
}
.addKnowledgeLoading .ant-spin-dot-circle {
  color: #1777ff;
  stroke-width: 10;
}

.addKnowledgeLoadingText {
  font-size: 1.9rem;
  text-align: center;
  font-weight: 600;
}

.editModal {
  flex-grow: 1;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border-radius: 20px;
}

.editModalColumn {
  flex-basis: 30rem;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  gap: 1rem;
  padding: 2rem;
}

.editModalColumn.first {
  flex-grow: 1;
  background-color: #f4f4f4;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  overflow: hidden;
}

.editModalColumn #cancel:hover {
  color: black !important;
  background-color: white !important;
  border-color: black !important;
}

.editModalColumn.second {
  flex-grow: 3;
}
.editModalColumn .versionSelector {
  display: flex;
  flex-direction: row;
  align-self: end;
  gap: 0.5rem;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 26px;
  text-align: center;
  color: #6d6d6d;
  cursor: default;
}
.editModalColumn .versionSelector .controll {
  cursor: pointer;
}
.editModalColumn .versionSelector .controll.disabled {
  cursor: not-allowed;
}

.editModalColumnTitle {
  font-size: 1.2rem;
  font-weight: 600;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.editModalRegenerateContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

.editModalRegenerateContainer .addKnowledgeTextArea {
  min-height: 10rem;
}
.editModalRequestsContainer {
  max-height: 15rem;
  width: 100%;
  overflow: auto;
}

.editModalRequestsContainer .requestsTitle {
  font-size: 1.2rem;
  font-weight: 600;
  border-bottom: 1px solid #c8c8c8;
  padding: 1rem;
  position: sticky;
  background-color: #f4f4f4;
  top: 0;
}

.editModalRequestsContainer .request {
  font-size: 1.2rem;
  font-weight: 400;
  text-align: left;
  border-bottom: 1px solid #c8c8c8;
  padding: 1rem;
  cursor: pointer;
  color: #4a4a4a;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.editModalRequestsContainer .request.selected {
  font-weight: 600;
  background-color: #e0dbdb5d;
}

.editModalRequestsContainer .request.disabled {
  opacity: 0.5 !important;
  cursor: not-allowed !important;
}

.editModalRequestsContainer .request:hover:not(.disabled) {
  background-color: #e0dbdb5d;
}

.editModalColumn .buttonsContainer {
  height: unset;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 0.5rem;
  flex-wrap: wrap;
}

.editModal .addKnowledgeLoading {
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
}
