.dashboard-container {
  padding: 3rem 1rem 3rem 1rem;
  position: relative;
  background: #f5f5f5;
  height: 100%;
  overflow-y: auto;
}

.content-wrapper {
  padding-top: 4rem;
  overflow: auto;
}

.sider-visible {
  width: 19rem;
  max-width: calc(100vw - 6rem);
  flex-shrink: 0;
  border-right: 2px solid #efefef;
  padding-right: 0.5rem;
}

.header {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.collapse-button {
  display: flex;
  align-items: center;
  border: none;
  background: none;
  cursor: pointer;
}

.logo-container {
  text-align: center;
  margin-bottom: 20px;
}

.service-logo {
  width: 6rem;
}

.row-cards {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;
}

.dashboard-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 20rem;
  aspect-ratio: 1;
  width: 95%;
  border-radius: 20px;
}

.dashboard-card-row {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 85%;
  border-radius: 20px;
  max-width: 63rem;
  height: auto !important;
  background-color: #ffffff;
}

.card-content {
  text-align: left;
  flex-grow: 1;
}

.card-icon {
  font-size: 35px;
  color: #8c8c8c;
  background: #f5f5f5;
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 16px;
}

.footer-card {
  border-radius: 20px;
  width: 90%;
  max-width: 63rem;
}

.table-card {
  background-color: #ffffff;
  border: 1px #e8e8e8;
  gap: 1rem;
  width: 90%;
  max-width: 63rem;
  border-radius: 20px;
  margin-top: 1rem;
}

.footer-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  flex-wrap: wrap;
}

.footer-paragraph {
  max-width: 50rem !important;
  margin-bottom: 0 !important;
}
