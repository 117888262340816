@import url('https://fonts.googleapis.com/css2?family=Dr+Sugiyama&display=swap');

.report-header {
  background-color: #567a8d;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.report-header .logo-overview {
  margin: 15px !important;
  width: 200px;
}

.report-header .title {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin: 10px !important;
  color: white;
  font-family: Switzer, serif;
  font-size: 18px;
  font-weight: 500;
}

.report-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 3vh;
}

.report-content .report-table {
  margin-bottom: 3vh;
  width: 100%;
  border-radius: 5px;
  background-color: white;
}

.report-content .report-table-title {
  background-color: #567a8d;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 8px;
}

.report-content .report-table-content {
  border: 1px solid #b5c8d1;
}

.report-content .report-table-item {
  border-right: 1px solid #b5c8d1;
  border-bottom: 1px solid #b5c8d1;
}

.report-content .report-table-item-side {
  border-bottom: 1px solid #b5c8d1;
}

.report-content .report-table-item-bottom {
  border-right: 1px solid #b5c8d1;
}

.evidences {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  padding: 20px;
}

.evidences img {
  width: 100%;
  object-fit: cover;
}

.report-table-content-sign {
  margin-top: 3vh;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  padding: 20px;
  text-align: center;
}

.signature-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
}

.sign-font {
  font-family: 'Dr Sugiyama', sans-serif !important;
  font-size: 40px;
  width: 100%;
  height: 54px;
}
