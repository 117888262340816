.sider-chats {
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  border-right: 2px solid #efefef;
  /* transition: all 0.2s ease; */
}
.sider-chats.collapsed {
  border-right: none;
}

.sider-chats ul {
  overflow: auto;
  overflow-x: hidden;
  width: 100%;
  height: calc(100% - 7rem) !important;
}

.custom-menu-chats .ant-menu-item-selected{
  color: #000
}
