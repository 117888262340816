.modalFullScreenWrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  background: #00000080;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  opacity: 0;
  pointer-events: none;
}
.modalFullScreenWrapper.closed {
  pointer-events: unset;
  opacity: 1;
}
.modalHeader {
  width: 100%;
  display: flex;
  align-items: start;
  justify-content: space-between;
}
.modalBodyContainer {
  height: auto;
  max-height: 43rem;
  width: 100%;
  max-width: 50rem;
  border-radius: 20px;
  background-color: white;
  box-shadow: 0px 4px 9.7px 0px #00000040;
  border: Mixed solid var(--Components-Input-Global-colorBorder, #d9d9d9);
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: start;
  padding: 3rem;
  padding-bottom: 8rem;
  position: relative;
}

.modalBodyContainer .closeIcon {
  align-self: flex-end;
  font-size: 1rem;
  padding-top: 0.4rem;
}

.modalBodyContainer .closeIcon:hover {
  cursor: pointer;
}

.modalBodyContainer .ant-btn-default:hover:not(.backButton) {
  color: white !important;
  border-color: white !important;
}

.modalContent {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  height: calc(100% - 8rem);
  width: 100%;
  overflow: auto;
  flex-grow: 1;
}

.modalField {
  width: 100%;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1rem;
}

.modalFieldLabel {
  flex-basis: 30%;

  font-size: 1rem;
  width: 20%;
  flex-shrink: 2;
}

.modalFieldNode {
  flex-basis: 60%;
  min-width: 20rem;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.modalFieldNode .ant-select {
  width: 100%;
}

.modalTitle {
  font-weight: 600;
  font-size: calc(1.5rem + 0.4vh);
  padding-bottom: 1rem;
}

.modalFooter {
  background-color: white;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 6rem;
  width: 100%;
  display: flex;
  padding: 2rem;
  border-radius: 20px;
  justify-content: space-between;
}
