.steps-arrow {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0 0 30px 0;
  list-style: none;
  line-height: 2rem;
  background-color: white;
  border-bottom: 1px solid #efefef;
  overflow: auto;
}
.steps-arrow::-webkit-scrollbar {
  display: none;
}

.step-content {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.step-arrow {
  height: 3rem;
  min-width: 10rem;
  flex-grow: 1;
  background: #e0e0e0;
  color: #333;
  clip-path: polygon(90% 0%, 100% 50%, 90% 100%, 0% 100%, 10% 50%, 0% 0%);
  text-align: center;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.progress-bar {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 1;
  transition: width 0.3s ease-in-out;
}

.step-arrow span {
  position: relative;
  z-index: 2;
}

.step-arrow:not(:first-of-type) {
  margin-left: -0.8rem;
}

.step-arrow:last-of-type {
  clip-path: polygon(100% 0%, 100% 50%, 100% 100%, 0% 100%, 9% 50%, 0% 0%);
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.steps-title {
  text-align: left;
  margin-bottom: 15px;
}

.steps-title h3 {
  font-size: 1.2rem;
  color: #333;
}
