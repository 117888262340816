/* Removing the default arrow */
select {
  -webkit-appearance: none;
  appearance: none;
}

select {
  background-image: url('../../images/openLine.svg');
  background-size: 15px;
  background-repeat: no-repeat;
  background-position: calc(100% - 15px) center;
}
