.treeContainer {
  padding-top: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.treeTitle {
  color: #686978;
  font-size: 1.3rem;
  font-weight: 600;
  line-height: 29.73px;
  text-align: left;
}
.tree {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.treeNode {
  color: #686978;
  min-height: 4rem;
  width: 100%;
  border: 0.92px solid #bbbbbb;
  border-radius: 22px;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;
  cursor: pointer;
}
.treeNode:hover {
  background-color: rgb(247, 247, 247);
  transform: scale(1.1);
}

.grow {
  transition: all 0.2s ease-in-out;
}
.grow:hover {
  transform: scale(1.05);
}

.treeNode.selected {
  background-color: #1777ff !important;
  color: white;
}

.treeNode.disabled {
  opacity: 50%;
  transform: none !important;
  cursor: not-allowed !important;
}

.treeSpacer {
  height: 1rem;
  width: 0.5px;
  border: 0.1px solid #bbbbbb;
}
.treeNodeIcon {
  display: flex;
  align-items: center;
  justify-content: center;
}
.treeNodeText {
  font-size: 1.3rem;
  font-weight: 600;
  line-height: 35.66px;
  text-align: left;
}
