.tripleColumn {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  flex-wrap: wrap;
  transition: all 0.3s;
  overflow: auto;
}

.tripleColumn .column {
  min-width: 15rem;
  max-width: 100%;
}
.tripleColumn .column.first {
  flex-grow: 2;
  border-bottom: 1px solid #ebecee;
}
.tripleColumn .column.second {
  flex-grow: 2;
  border: 1px solid #ebecee;
  border-top: none;
}
.tripleColumn .column.third {
  /* max-width: 60%; */
  flex-grow: 3;
}
