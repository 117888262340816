.user-container {
  display: flex;
  align-items: center;
}

.user-icon-container {
  display: flex;
  align-items: center;
}

.username {
  color: #000;
}
