.orderByButton {
  position: relative;
  height: 3rem;
  width: 4.5rem;
  aspect-ratio: 1/1;

  display: flex;
  align-items: center;
  justify-content: center;
  border: 2.24px solid #ebecee;
  background-color: white;
  border-radius: 8px;
  cursor: pointer;
}
.orderByButton:hover {
  background-color: white;
}

.orderByButton:after {
  border-radius: 8px;
  content: '';
  position: absolute;
  background: #ebecee;
  padding-top: 100%;
  padding-left: 100%;
  opacity: 0;
  transition: all 0.3s;
}
.orderByButton:active:after {
  padding: 0;
  margin: 0;
  opacity: 1;
  transition: 0s;
}
