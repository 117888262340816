.commissioningContent {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.commissioningPhasesLine {
  width: 100%;
  padding: 1rem;
  min-height: 4rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.3rem;
}
.commissioningPhase {
  min-height: 3rem;
  flex-basis: 16%;
  flex-grow: 1;
  background-color: #eeeeee;
  border: 1px solid #d3d3d3;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem;
  font-weight: 400;
  text-align: center;
  text-decoration-skip-ink: none;
  color: #6c6c6c;
  clip-path: polygon(10% 0, 90% 0, 100% 50%, 90% 100%, 10% 100%, 0% 50%);
}

.commissioningPhase.selected {
  background-color: #1777ff;
  color: white;
}

.previousnextButtonsContainer {
  position: sticky;
  bottom: 0;
  align-self: flex-end;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  padding: 1rem;
  min-height: 5rem;
  width: 100%;
  /* background-color: black; */
  align-items: center;
  justify-content: end;
  gap: 0.5rem;
}
.previousnextButtonsContainer #cancel:hover {
  color: white !important;
  background-color: #ff4d4f !important;
  border-color: #ff4d4f !important;
}
.arrow-steps {
  width: 100%;
  display: flex;
  align-items: center;
  margin: 0;
  flex-wrap: nowrap;
  overflow: auto;
  flex-shrink: 0;
}
.arrow-steps::-webkit-scrollbar {
  display: none;
}
.step {
  height: 3rem;
  min-width: 10rem;
  flex-grow: 1;
  background: #e0e0e0;
  color: #333;
  clip-path: polygon(90% 0%, 100% 50%, 90% 100%, 0% 100%, 10% 50%, 0% 0%);
  text-align: center;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.step.current {
  background-color: #1777ff;
  color: white;
}

.step.done {
  background-color: #0e305f;
  color: white;
}

.step:not(:first-of-type) {
  margin-left: -0.8rem;
}
.step:last-of-type {
  clip-path: polygon(100% 0%, 100% 50%, 100% 100%, 0% 100%, 9% 50%, 0% 0%);
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
.commissioningPhaseContainer {
  height: 100%;
  width: 100%;
  display: flex;
  gap: 1rem;
  overflow: auto;
  flex-direction: column;
}
.commissioningPhaseContainerTitle {
  z-index: 2;
  padding-top: 3rem;
  padding-bottom: 1rem;
  background-color: white;
  position: sticky;
  top: 0;
  font-size: 1.4rem;
  font-weight: 600;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.commissioningPhaseStages {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.commissioningPhaseText {
  font-size: 1.1rem;
  font-weight: 400;
}

.commissioningPhaseStage {
  padding: 1rem;
  padding-right: 2rem;
  padding-bottom: 4rem;
  border-bottom: 2px solid #efefef;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: end;
}
.commissioningPhaseStage #cancel:hover {
  color: white !important;
  background-color: #ff4d4f !important;
  border-color: #ff4d4f !important;
}
.addStageButton {
  display: flex;
  align-items: start;
  z-index: 2;
  padding-top: 1rem;
  background-color: white;
}
