.systemComponentsContainer {
  height: 100%;
  min-height: 20rem;
  width: 100%;
  border-radius: 12px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='14' ry='14' stroke='%23BEBEBEFF' stroke-width='3' stroke-dasharray='15%2c 15' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e");
  border-radius: 14px;
  padding: 1rem;
  margin-top: 1rem;
}
.systemComponentsExtraPadding {
  overflow: auto;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.systemComponentsContainer::-webkit-scrollbar {
  display: none;
}

.manageSystemButtonsContainer {
  bottom: 0;
  right: 0;
  align-self: flex-end;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  min-height: 5rem;
  width: 100%;
  align-items: center;
  justify-content: end;
  gap: 1rem;
}
.systemComponentContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-height: 4rem;
  padding: 0 1rem 0 1rem;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 2px 5px 0px #0000000d;
}

.systemComponentContainer.highlight {
  animation: highlight 1.5s ease-in-out;
}

.systemComponentTitleContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  overflow: hidden;
}

.systemComponentTitle {
  font-size: 1.3rem;
  font-weight: 600;
  text-align: left;
}

.systemComponentIcon {
  min-height: 1.5rem;
  width: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.systemComponentIcon img {
  height: auto;
  object-fit: contain;
}

.systemComponentClose {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1/1;
  transition: all 0.2s ease;
  cursor: pointer;
}

.systemComponentClose:hover {
  scale: 1.1;
}

@keyframes highlight {
  0% {
    background-color: #ffffff;
  }
  50% {
    background-color: #e9f2ff;
  }
  100% {
    background-color: #ffffff;
  }
}

#assets .column::-webkit-scrollbar {
  display: none;
}

.addToSystem {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  cursor: pointer;
}

.addToSystem:hover {
  scale: 1.1;
}

.addToSystem svg {
  font-size: 1.5rem;
}

.manageSystemButtonsContainer #cancel:hover {
  color: white !important;
  background-color: #ff4d4f !important;
  border-color: #ff4d4f !important;
}
