.criteriaContainer {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
}

.arrowStepsContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  gap: 1rem;
}

.arrowStepsContainer::-webkit-scrollbar {
  display: none;
}

.tasksContent {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.tasksContentHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1rem;
}

.tasksContentHeader .pageTitle {
  font-size: 1.2rem;
}

.reportTableButtons {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  flex-wrap: wrap;
}
