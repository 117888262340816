.allUsersTab {
  height: 100%;
  width: 100%;
  padding-top: 3rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.addUsersModal {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.modalContentHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  flex-wrap: wrap;
  gap: 1rem;
}
