.resizable-handle {
  width: 2px;
  background-color: #e3e9ee;
  user-select: none;
  height: 100vh;
  transition:
    background-color 0.2s ease,
    width 0.2s ease;
  cursor: ew-resize;
}

.resizable-handle:hover {
  background-color: #d3d3d3;
  width: 4px;
}

.resizable-handle.dragging {
  background-color: #a0a0a0;
  width: 5px;
}
