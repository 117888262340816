#assetCategories {
  height: 100%;
  width: 100%;
  overflow-y: auto;
}

.columnContainer {
  height: 100%;
  width: 100%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
}
.columnContainer::-webkit-scrollbar {
  display: none;
}
.columnHeader {
  display: flex;
  flex-basis: 3rem;
  flex-wrap: wrap;
  gap: 1rem;
  align-items: start;
  justify-content: space-between;
}

.columnTitle {
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 30.77px;
  text-align: left;
}

.searchControls {
  padding-top: 1.5rem;
  display: flex;
  align-items: center;
  width: 100%;
}

.columnHeader .ant-btn-default:hover {
  color: white !important;
  border-color: white !important;
}
.categoryList {
  max-height: calc(100vh - 15rem);
  overflow-y: auto;
  padding-top: 2rem;
  padding-bottom: 2rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: -2rem;
  gap: 0.5rem;
}

.categoryListDivider {
  width: 100%;
  border-bottom: 1px solid #ebecee;
}

.categoryListItem {
  border-radius: 12px;
  width: calc(95%);
  min-height: 4rem;
  padding-left: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  font-size: 1.2rem;
  font-weight: 600;
  text-align: left;
  cursor: pointer;
}
.categoryListItem:hover {
  background-color: rgb(240, 240, 240);
}

.categoryListItem.selected {
  background-color: #333333 !important;
  color: white;
}
.categoryListItem.disabled {
  /* background-color: rgb(247, 247, 247) !important; */
  opacity: 50%;
  cursor: not-allowed;
}
.categoryListItem.creating {
  /* color: #c0c1c2; */
  font-size: 1.3rem;
  font-weight: 600;
  line-height: 30.77px;
}
.categoryPropertiesContainer {
  padding-top: 1.5rem;
  padding-bottom: 2rem;
}
.categoryCommissioning {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 2rem;
  border-top: 1px solid #ebecee;
  border-bottom: 1px solid #ebecee;
  padding: 2rem;
}
.categoryCommissioningTitleContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}

.categoryCommissioningTitle {
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 30.77px;
  text-align: left;
}

.categoryCommissioningCheck {
  width: 100%;
}
.newPropertyButtonContainer {
  padding: 2rem;
  z-index: 2;
  width: fit-content;
}
.newPropertyButton .ant-btn-default:hover {
  color: white !important;
  background-color: #1777ff !important;
}
.manageCategoriesButtonsContainer {
  align-self: flex-end;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  padding: 1rem;
  min-height: 5rem;
  width: 100%;
  /* background-color: black; */
  align-items: center;
  justify-content: end;
  gap: 1rem;
}
.manageCategoriesButtonsContainer #cancel:hover {
  color: white !important;
  background-color: #ff4d4f !important;
  border-color: #ff4d4f !important;
}
.manageCategoriesButtonsContainer #create.disabled {
  opacity: 50%;
}
.manageCategoriesButtonsContainer #create:hover {
  color: white !important;
  background-color: #1777ff !important;
}
.categorySecondaryPropertyContainer {
  border-bottom: 1px solid #ebecee;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 2rem;
  padding-right: 2rem;
}
.categorySecondaryPropertyHeader {
  cursor: pointer;
  min-height: 6rem;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
}
.categorySecondaryTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}
.categorySecondaryTitleText {
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 30.77px;
  text-align: left;
}
.categorySecondaryTitleDelete {
  padding-top: 3px;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.categorySecondaryTitleDelete img {
  height: 1.2rem;
  object-fit: contain;
}
.categorySecondaryArrow {
  padding-top: 3px;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.categorySecondaryArrow img {
  height: 0.5rem;
  object-fit: contain;
}
.categorySecondaryPropertyContent {
  height: 0;
  transition: all 0.2s ease-in-out;
  overflow: hidden;
  padding-top: 1rem;
}
.categorySecondaryPropertyContent.open {
  height: 16rem;
  padding-bottom: 1rem;
  overflow: auto;
}
.categorySecondaryPropertyContent::-webkit-scrollbar {
  display: none;
}
.addPropertyOptionContainer {
  height: 2rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
}
.addPropertyIcon {
  display: flex;
  align-items: center;
}
.addPropertyOption {
  cursor: pointer;
  width: 100%;
  font-size: 1rem;
  font-weight: 600;
  text-align: left;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  justify-content: start;
}

.deletePropertyIcon {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 3px;
  cursor: pointer;
}
.addPropertyOption img {
  padding-top: 3px;
}
