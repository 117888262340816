.formsBody {
  /* height: 100%; */
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  /* overflow: auto; */
}

.formsBody::-webkit-scrollbar {
  display: none;
}

.formsInputContainer {
  min-height: 3rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  flex-wrap: wrap;
}
.formsInputLabel {
  font-size: 1.1rem;
  font-weight: 400;
  text-align: left;
  flex-basis: 30%;
}
.formsInput {
  flex-basis: 50%;
  min-width: 20rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-content: center; */
  gap: 1rem;
}
