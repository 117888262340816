.checkboxContainer {
  width: 100%;
  display: flex;
  gap: 1rem;
  align-items: center;
}

.checkboxLabel {
  font-family: Switzer;
  font-size: 16.6px;
  font-weight: 400;
  line-height: var(--ComponentsFormGloballineHeight);
  text-align: left;
}

/* .customCheckbox {
  width: 1.1rem;
  height: 1.1rem;
  border-radius: 5px;
  border-top: 1.5px solid var(--Components-Checkbox-Global-colorBorder, #d9d9d9);
  background: var(#ffffff);
} */

input[type='checkbox'] {
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  font: inherit;
  color: #1777ff;
  width: 1.2rem;
  height: 1.2rem;
  border: 1px solid #ebecee;
  border-top: 1.5px solid var(--Components-Checkbox-Global-colorBorder, #d9d9d9);
  border-radius: 5px;
  display: grid;
  place-content: center;
}
input[type='checkbox']::before {
  content: '';
  width: 0.65em;
  height: 0.65em;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
  background-color: #1777ff;
  /* transform-origin: bottom left; */
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
}
input[type='checkbox']:checked::before {
  transform: scale(1);
}
input[type='checkbox']:focus {
  outline: max(2px, 0.15em) solid #1777ff;
  outline-offset: max(2px, 0.15em);
}

.checkboxContainer.disabled input[type='checkbox']::before {
  background-color: gray;
}
