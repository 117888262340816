.breadcrumbsContainer {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 0.3rem;
}

.breadcrumb {
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 0.3rem;
  border-radius: 3rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  transition: all.2s ease-in-out;
}
.breadcrumb:hover {
  background-color: #e3e9ee;
  box-shadow: 0px 5px 5px 0px #00000005;
}
.breadcrumb.disabled {
  cursor: not-allowed;
  opacity: 50%;
}

.breadcrumbText {
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 30.77px;
  text-align: left;
}

.breadcrumbText.current {
  font-weight: bold;
}

.breadcrumbDivider {
  cursor: default;
}
