.doubleColumn {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
}

.doubleColumn .column {
  height: 100%;
  max-width: 100%;
  flex-basis: 25rem;
  overflow: auto;
}

.doubleColumn .column.first {
  flex-grow: 1;
  /* min-width: 30rem; */
}
.doubleColumn .column.second {
  flex-grow: 3;
  /* min-width: 40rem; */
}

.doubleColumn .column.second:not(.noBorder) {
  border-left: 1px solid #ebecee;
}
