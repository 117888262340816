.siderMenu {
  padding: 1rem;
  padding-top: 0;
  padding-bottom: 4.6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

.siderMenu.mobile {
  padding: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 100%;
  gap: 0.5rem;
}

.menu:first-child {
  margin-top: 1rem !important;
}

.menuList {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.menuButton {
  border: 1px solid;
  border-image-source: linear-gradient(180deg, #eeeff0 0%, #e0e1e2 100%);
  position: relative;
  min-height: 2.5rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 0.5rem;
  cursor: pointer;
  color: #686978 !important;
  padding-left: 1rem;
  border-radius: 12px;
  z-index: 8;
}

.menuButton.disabled {
  opacity: 50% !important;
}
.menuButton.disabled:hover {
  box-shadow: unset;
  background-color: unset;
  cursor: default;
}

.menuButton.collapsed {
  padding: 0 !important;
  justify-content: center;
}

.menuButton.selected {
  border: 1px solid;
  border-image-source: linear-gradient(180deg, #eeeff0 0%, #e0e1e2 100%);
  box-shadow: 0px 2px 5px 0px #0000000d;
  background-color: #ffffff !important;
}
.menuButton.loading {
  border-image-source: linear-gradient(180deg, #eeeff0 0%, #e0e1e2 100%);
  box-shadow: 0px 2px 5px 0px #0000000d;
}

.menuButton:hover {
  border: 1px solid;
  border-image-source: linear-gradient(180deg, #eeeff0 0%, #e0e1e2 100%);
  box-shadow: 0px 2px 5px 0px #0000000d;
  background-color: #ffffff7a;
}

.siderModule {
  transition: all 0.2s ease;
  min-height: 2.5rem;
  width: 100%;
}

.siderModule:hover .wrapper.collapsed {
  display: block !important;
  z-index: 7;
}

.wrapper.collapsed:hover {
  display: block !important;
  z-index: 7;
}
.wrapper.collapsed {
  display: none;
  padding-left: 5rem;
  position: absolute;
}

.moduleContent {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.moduleContent.collapsed {
  z-index: 2;
  width: 16rem;
  padding: 1rem;
  border-radius: 12px;
  background-color: #e3e9ee;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.wrapper.collapsed.mobile {
  bottom: 5rem;
  padding-left: 0;
  padding-bottom: 1rem;
}

.siderModuleContent {
  min-height: 2.5rem;
  width: 100%;
}

.moduleMenu {
  min-height: 2.5rem;
  width: 100%;
}

.moduleMenuContent {
  padding-left: 2rem;
}

.menuOption {
  width: 100%;
}

.menuOptionContent {
  padding-left: 4rem;
}

.siderDivider {
  height: 2px;
  width: 100%;
  background-color: #dadee4;
  margin: 1rem;
}

.siderMenu .siderDivider:first-of-type {
  margin-top: 1vh;
}
.skeleton-box {
  border-radius: 12px;
  display: inline-block;
  height: 1em;
  position: relative;
  overflow: hidden;
  background-color: rgba(221, 219, 221, 0.719);
}

.skeleton-box::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.2) 20%,
    rgba(255, 255, 255, 0.5) 60%,
    rgba(255, 255, 255, 0) 100%
  );
  animation: shimmer 2s infinite;
  content: '';
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
