#assets {
  height: 100%;
  width: 100%;
  overflow: auto;
}
#assets .controls {
  display: flex;
  /* align-items: stretch;
  justify-content: stretch; */
  flex-wrap: wrap;
  gap: 0.5rem;
  width: 100%;
}
.manageLevelButtonsContainer {
  bottom: 0;
  right: 0;
  align-self: flex-end;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  padding: 1rem;
  min-height: 5rem;
  width: 100%;
  align-items: center;
  justify-content: end;
  gap: 1rem;
}
.manageLevelButtonsContainer #cancel:hover {
  color: white !important;
  background-color: #ff4d4f !important;
  border-color: #ff4d4f !important;
}
.manageLevelButtonsContainer #create.disabled {
  opacity: 50%;
}
.manageLevelButtonsContainer #create:hover {
  color: white !important;
  background-color: #1777ff !important;
}

.viewDetailsContainer {
  display: flex;
  align-items: start;
  justify-content: start;
  gap: 1rem;
  height: 100%;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.viewDetailsContainer.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.viewDetailsContainer:hover {
  scale: 1.05;
  color: #1777ff;
}

.viewDetailsIcon {
  display: flex;
  align-items: start;
  height: 100%;
}
.viewDetailsIcon img {
  padding-top: 5px;
}

.viewDetailsText {
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 30.77px;
  text-align: left;
}
