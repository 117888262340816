.assetTreeContainer {
  /* height: 100%; */
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  padding-top: 1rem;
}

.assetTreeNode {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  font-size: 1.5rem;
  line-height: 30.77px;
  font-weight: 400;
  text-align: left;
  gap: 5px;
}

.assetTreeNodeHeader.plant .assetTreeNodeTitle {
  font-weight: 600;
}

.assetTreeNodeHeader .assetTreeNodeTitle.creating {
  font-weight: 600;
  text-decoration: black solid underline;
  /* color: #a9a9a9; */
}

.assetTreeNodeHeader {
  width: 100%;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  align-items: center;
  gap: 1rem;
}

.assetTreeNodeHeader.disabled {
  opacity: 50%;
  cursor: not-allowed;
}

.assetTreeNodeHeader.disabled .assetTreeNodeHeaderContent:hover {
  background-color: unset;
}

.assetTreeNodeHeaderContent {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-radius: 8px;
  gap: 1rem;
  flex-wrap: wrap;
}

.assetTreeNodeTitleContainer {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.assetTreeNodeHeaderContent.selected {
  background-color: #e9f2ff !important;
  box-shadow: 0px 5px 5px 0px #00000005;
}
.assetTreeNodeHeaderContent:hover {
  background-color: rgb(247, 247, 247);
}

.assetTreeNodeIcon {
  display: flex;
  align-items: center;
}

.assetTreeNodeArrow {
  width: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.assetTreeNodeArrow img {
  padding-top: 5px;
}

.assetTreeNodeContent {
  height: auto;
  transition: all 0.2s ease-in-out;
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.assetTreeNodeContent.closed {
  height: 0;
  overflow: hidden;
}

.assetTreeNodeContentLineContainer {
  width: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #989da9;
}
.assetTreeNodeContentLine {
  height: 100%;
  border: 1px solid #989da9;
}

.childAssetTree {
  height: 100%;
  width: 100%;
}

.addToSystemContainer {
  transition: all 0.2s ease;
}
.addToSystemContainer:hover {
  scale: 1.1;
}
