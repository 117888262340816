.notFoundContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
}

.notFoundContainer h1 {
  font-size: 6rem;
  margin-bottom: 1rem;
}

.notFoundContainer h2 {
  margin-bottom: 1rem;
}

.notFoundContainer p {
  margin-bottom: 2rem;
}
