#assetDetails {
  position: relative;
}

.detailsTab {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 4rem;
  overflow: auto;
}

.columnTitle.sticky {
  position: sticky;
  background-color: white;
  top: 0;
  width: 100%;
  z-index: 2;
  min-height: 3rem;
  padding-bottom: 1rem;
}

.saveButton {
  z-index: 3;
  position: absolute;
  right: 2rem;
  bottom: 2rem;
  display: flex;
  gap: 1rem;
}

.propsFormContent {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 2rem;
  flex-wrap: wrap;
}
.propsColumn {
  flex-basis: 45%;
  flex-grow: 1;
}
.saveButton #cancel:hover {
  color: white !important;
  background-color: #ff4d4f !important;
  border-color: #ff4d4f !important;
}
