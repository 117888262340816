#sider {
  color: #ffff;
  background-color: #e3e9ee;
  height: auto;
}

#sider .logo {
  height: auto;
  max-width: 50%;
  object-fit: contain;
  transition: all 0.3s ease;
}

.logo-collapsed {
  width: 50%;
  margin: 0 auto;
  transition: all 0.3s ease;
}

#sider .logo.footer {
  height: auto;
  max-width: 50%;
  object-fit: contain;
  padding: 0.5rem;
  padding-left: 1rem;
  transition: all 0.3s ease;
}

#sider .ant-layout-sider-children {
  width: 100%;
  overflow: auto;
}
#sider .ant-layout-sider-children::-webkit-scrollbar {
  display: none;
}

.custom-menu {
  background-color: #e3e9ee !important;
  border-inline-end: none !important;
}

#sider .head {
  width: 100%;
  min-height: 4.6rem;
  padding-top: 1rem;
  padding-left: 1rem;
  display: flex;
}

#sider .head:hover {
  cursor: pointer;
}

#sider .bottom {
  background-color: #e3e9ee;
  z-index: 7;
  width: 100%;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  padding: 1rem;
  border-top: 2px solid #d6d9df;
}

#sider .bottom.opened {
  justify-content: end;
}

#sider .bottom img {
  height: 40%;
  object-fit: contain;
  cursor: pointer;
}
