#chatContent {
  height: 100%;
  width: 100%;
  position: relative;
}

#chatMessages {
  height: 100%;
  width: 100%;
  background-color: white;
  padding: 3rem;
  padding-bottom: 6rem;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  overflow: auto;
}

@media (max-width: 980px) {
  #chatMessages {
    padding: 1rem !important;
    padding-bottom: 6rem !important;
  }
}

.messageRow .info {
  margin-top: 1vh;
  align-self: flex-end;
}

.messageRow .info:hover {
  cursor: pointer;
}

.messageRow {
  display: flex;
  align-items: end;
  min-height: 3rem;
  flex-shrink: 0;
  gap: 1rem;
  animation: messageAppear 1s;
  -webkit-animation: messageAppear 1s;
}

.botMessageRow {
  justify-content: start;
}

.userMessageRow {
  justify-content: end;
}

.messagePicture {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  height: 2.5rem;
  aspect-ratio: 1/1;
  overflow: hidden;
}

.messagePicture img {
  height: 100%;
  width: auto;
  object-fit: contain;
}

.messagePicture span {
  height: 100%;
  width: 100%;
  font-size: medium;
  display: flex;
  justify-content: center;
  align-items: center;
}

.markdown-message img {
  max-width: 50%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 980px) {
  .messagePicture {
    display: none !important;
  }
}
.botMessagePicture {
  background: linear-gradient(180deg, #1677ff 0%, #0d4799 63%);
  padding: 0.5rem;
  padding-left: 0.6rem;
}

.userMessagePicture {
  border: solid 1px #d9d9d9;
}

.messageBubble {
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  line-height: 30px;
  align-items: start;
  min-height: 3rem;
  max-width: 60%;
  min-width: 10rem;
  box-shadow: 0px 8px 25px 7px #00000005;
  box-shadow: 0px 2px 5px -3px #0000001f;
  box-shadow: 0px 4px 12px 0px #0000000a;
  border-radius: 8px;
  padding: 1rem;
  text-align: start;
  overflow-x: auto;
}

@media (max-width: 980px) {
  .messageBubble {
    max-width: 100% !important;
  }
}

.botMessageBubble {
  background-color: #e1eeff;
  border: 1px solid #bcd8ff;
  color: #002558;
}

.userMessageBubble {
  background-color: #2d85ff;
  border: 1px solid #1a6fe7;
  color: white;
}

.thinkingContainer {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.thinkingDot {
  background-color: #bcd8ff;
  height: 0.7rem;
  aspect-ratio: 1/1;
  border-radius: 100%;
  animation: thinkingDot 1.2s infinite;
  -webkit-animation: thinkingDot 1.2s infinite;
}

.thinkingDot.one {
  animation-delay: 0.2s;
}
.thinkingDot.two {
  animation-delay: 0.4s;
}

#chatMessageBar {
  display: flex;
  align-items: center;
  background-color: white;
  position: absolute;
  padding-bottom: 0.5rem;
  bottom: 0;
  left: 0;
  right: 0;
  margin-inline: auto;
  height: fit-content;
  width: fit-content;
  width: 95%;
  height: 4rem;
}

@keyframes messageAppear {
  from {
    opacity: 0;
    transform: translateY(100px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes thinkingDot {
  0%,
  80%,
  100% {
    opacity: 0;
    transform: scale(0);
  }
  40% {
    opacity: 1;
    transform: scale(1);
  }
}
