.pageWithScrollableSider {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow: auto;
}
.pageWithScrollableSider::-webkit-scrollbar {
  display: none;
}

.scrollableSider {
  min-height: 20rem;
  min-width: 30rem;
  flex-grow: 1;
  overflow: auto;
  background-color: #f9fbff;
  border-right: 1px solid #ebecee;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  gap: 1rem;
}

.pageContent {
  display: flex;
  flex-direction: column;
  flex-basis: 30rem;
  flex-grow: 7;
  padding: 2rem;
  gap: 1rem;
}
.pageContent .buttonsContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: unset;
}
