#layout {
  overflow: hidden;
  height: 100%;
  width: 100%;
  background-color: #e3e9ee;
}

#content-outlet {
  height: 100%;
  width: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
}

.border-content {
  margin: 10px 5px 0 0;
  border-radius: 16px 16px 0 0;
  overflow: hidden;
  background-color: #fff;
  padding: 1px 1px 0;
}
