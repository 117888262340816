.formatedDateContainer {
  height: 100%;
  width: 80%;
  pointer-events: none;
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 8px;
  padding-left: 3rem;
}

.formatedDate.disabled {
  color: #969696;
}

.formatedDate {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  background-color: white;
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 26.66px;
  letter-spacing: -0.02em;
  text-align: left;
}

input[type='date']::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}
