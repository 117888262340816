#reports {
  height: 100%;
  width: 100%;
  padding: 3rem;
  display: flex;
  flex-direction: column;
  overflow: auto;
  gap: 2rem;
}

.woContentHeader .pageTitle {
  font-size: 1.3rem;
}

.pageContentBlock {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.blockContent {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
}

.detailsRow {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: space-between;
}

.detailsRow .formsBody {
  flex-basis: 40%;
  width: unset;
  flex-grow: 1;
  flex-direction: column;
  justify-content: end;
}

#reports .formsInput {
  min-width: unset;
  flex-basis: 60%;
}
#reports .formsInput .inputContainer input {
  background: #0000000a;
}

#reports .cards {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
}

#reports .doubleCard {
  overflow: auto;
  flex-basis: 49%;
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
}

#reports .card {
  flex-basis: 20rem;
  height: 12rem;
  flex-grow: 1;
  border: 2px solid #ebecee;
  box-shadow: 0px 5px 20px 0px #e2e2e21a;
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  transition: all ease 0.2s;
}

#reports .card.disabled {
  cursor: not-allowed !important;
  opacity: 0.5;
}

#reports .card:hover {
  border-color: #d4d4d4;
  cursor: pointer;
  box-shadow: 0px 4px 12px 0px #0000000a;
}

#reports .card.selected {
  border-color: #1777ff;
  box-shadow: 0px 4px 12px 0px #0000000a;
}

#reports .card .cardHeader {
  transition: all ease 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4.5rem;
  border-bottom: 2px solid #ebecee;
  font-size: 1.4rem;
  font-weight: 500;
}
#reports .card .cardBody {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-size: 4rem;
  font-weight: 400;
  letter-spacing: -0.02em;
  color: #1777ff;
}

.pageContentHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1rem;
}
.headerButtons {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  flex-wrap: wrap;
}

.advancedFiltersModal {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  gap: 2rem;
}

.advancedFiltersModal .formsBody {
  flex-basis: 30rem;
  flex-grow: 1;
}

.advancedFiltersModal .closeModal:hover {
  border-color: black !important;
  background: white !important;
  color: black !important;
}
