.tabs {
  top: -2px;
  height: 100%;
  width: 100%;
}
.tabsContainer {
  height: 4rem;
  width: 100%;
  padding-top: 1rem;
  padding-left: 3rem;
  padding-right: 3rem;
  border-bottom: 2px solid #efefef;
  display: flex;
  gap: 1rem;
  overflow-x: auto;
}
.tabsContainer::-webkit-scrollbar {
  display: none;
}

.tab {
  flex-shrink: 0;
  cursor: pointer;
  height: 100%;
  padding-left: 2rem;
  padding-right: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 30.77px;
  text-align: left;
  transition: all 0.2s ease;

  border-bottom: 2px solid transparent;
}
.tab:hover {
  border-bottom: 2px solid #00000056;
}

.tab.selected {
  border-bottom: 2px solid #000000 !important;
}
.tab.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.chosenTabContent {
  height: calc(100% - 3rem);
  padding: 3rem;
  overflow: auto;
}

.chosenTabContent::-webkit-scrollbar {
  display: none;
}
