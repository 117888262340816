.woContent {
  height: 100%;
  width: 100%;
  padding: 2rem;
  padding-top: 3rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.woContentHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1rem;
}

.pendingTasksColumn {
  display: flex;
}

.pendingTasksColumn .tableTipContainer .tableTip {
  width: 100%;
}

.pendingTasksColumn .tableTipContainer + .tableTipContainer {
  margin-left: 1vh;
}
