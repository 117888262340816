#header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #ffff;
  height: 5rem;
  border-bottom: 2px solid #efefef;
}

#header.has-menu {
  padding: 12px 30px 0;
}

#header.no-menu {
  overflow: hidden;
  padding: 1rem;
}

.header-content {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
}

#header .title {
  height: 100%;
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;
}

#header .productName,
#header .sectionName {
  font-size: large;
  margin-bottom: 10px;
}

#header .sectionName {
  margin-left: 0.5vw;
  color: #797979;
}

#header .ant-menu {
  align-items: center;
}

#header .icon {
  color: #797979;
}

.header-buttons {
  height: 100%;
  display: flex;
  align-items: center;
  gap: 1.2rem;
}
.header-buttons-divider {
  border-right: 2px solid #ebecee;
  height: 100%;
}

.ant-layout-header {
  line-height: 2px !important;
}

@media (max-width: 422px) {
  .header-buttons {
    flex-direction: row;
    align-items: center;
  }

  .notification-bell {
    margin-top: 5px;
  }
}

.breadcrumbs {
  color: black;
}
