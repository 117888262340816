#assetTree {
  height: 100%;
  width: 100%;
  overflow-y: auto;
}

.doubleTreeSpacerContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
}

.buttons {
  width: 10rem;
  position: absolute;
  display: flex;
  gap: 1rem;
  right: 6rem;
  bottom: 2rem;
}
.continueButton:disabled {
  opacity: 50%;
}
.continueButton:hover {
  color: white !important;
}
#assetTree .ant-btn-default:hover:not(.goBack) {
  color: white !important;
  border-color: #1777ff !important;
}
.goBack:hover {
  color: #1777ff !important;
}
.goBack:disabled {
  opacity: 50%;
}
.columnSubTitle {
  display: flex;
  max-width: 90%;
  flex-direction: row;
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 1.6rem;
  text-align: left;
}

.assetTreeProgressContainer {
  display: flex;
  flex-direction: column;
  align-items: start;
  min-height: 15rem;
  justify-content: space-around;
}

.assetTreeProgressIndicator {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 1rem;
}
.assetTreeProgressBubble {
  height: 1.6rem;
  aspect-ratio: 1/1;
  border-radius: 100%;
  border: 4px solid #f4f4f4;
}
.assetTreeProgressBubble.active {
  border: 4px solid #1677ff;
}
.assetTreeProgressBubble.done {
  border: 4px solid #1677ff;
  background-color: #1677ff43;
}
.assetTreeProgressSpacer {
  border: 2px dashed #f4f4f4;
  height: 100%;
  margin-left: 0.6rem;
  width: 2px;
}
.assetTreeProgressSpacer.active {
  border: 2px dashed #1677ff;
}
.assetTreeProgressSpacer.done {
  border: 2px solid #1677ff;
}
.assetTreeProgressText {
  font-size: 18px;
  font-weight: 600;
  color: #000000e0;
  opacity: 0.3;
  letter-spacing: -0.01em;
  text-align: left;
}

.assetTreeProgressText.active {
  color: #2d85ff;
  opacity: 1;
}
.assetTreeProgressText.done {
  color: #2d85ff;
  opacity: 1;
}
