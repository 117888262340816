.toolTipContainer {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}

.toolTipIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding-top: 3px;
}

.toolTipIcon:hover + .toolTipTextContainer {
  display: flex;
  opacity: 1;
}

.toolTipTextContainer {
  box-shadow: 0px 2px 5px 0px #0000000d;
  padding: 1rem;
  pointer-events: none;
  position: absolute;
  opacity: 0;
  background-color: white;
  min-height: 2rem;
  min-width: 10rem;
  transition: all 0.5s;
  border: 0.92px solid #bbbbbb;
  border-radius: 8px;
  line-break: strict;
  align-items: center;
  z-index: 2;
}
.toolTipText {
  font-size: 0.8rem;
  font-weight: 400;
}
