.generalTab {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding-left: 1rem;
  padding-right: 1rem;
  overflow: auto;
}
.categoryCommissioning.detailPage {
  padding-left: 0;
  padding-right: 0;
  margin-bottom: -1.5rem;
}

.categorySecondaryPropertyContainer.detailPage {
  padding-left: 0;
  padding-right: 0;
}

.manageCategoriesButtonsContainer.detailPage {
  position: sticky;
  bottom: 0;
  padding-right: 0;
}
