.systemDependency {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-top: 1rem;
}

.systemDependencyHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: space-between;
}

.systemNameContainer {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.systemName {
  font-size: 1.5rem;
  font-weight: 600;
  text-align: left;
}

.systemProgressContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}

.systemProgressInfo {
  font-size: 1.2rem;
  color: #1777ff;
  font-weight: 500;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.progress-container {
  position: relative;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hollow-circle {
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
  position: absolute;
  left: 0;
  right: 0;
  margin-inline: auto;
  background-color: white;
}

.progress-circle {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.systemContent {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-size: 1rem;
}
