.favorites {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  flex-wrap: wrap;
  transition: all 0.3s;
  overflow: auto;
}

.favorite {
  height: 100%;
  min-width: 15rem;
  width: 33%;
  max-width: 100%;
  padding: 2rem;
  display: flex;
  cursor: pointer;
  flex-direction: row;
}

.border {
  border-right: #e8e8e8 solid 1px;
}

.name {
  font-size: 1.2rem;
  display: flex;
  flex-direction: column;
  justify-content: left;
  color: #292929;
  width: 50%;
  font-weight: 600;
}

.serial {
  display: flex;
  flex-direction: column;
  text-align: right;
  font-size: 1rem;
  color: #8f8f8f;
  width: 50%;
}
