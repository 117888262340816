.newWOModalBody {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  gap: 2rem;
}

.equipmentTable {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
}
.equipmentTable::-webkit-scrollbar {
  display: none;
}

.newWOModalBody .equipmentTable .dropdown-list {
  bottom: 3rem;
  border-radius: unset;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}
.newWOModalBody .checkboxContainer {
  justify-content: center;
}
.newWOModalBody #cancel:hover {
  color: white !important;
  background-color: #ff4d4f !important;
  border-color: #ff4d4f !important;
}
.tokenPreviewerSubTitle {
  font-size: 1.3rem;
  font-weight: 400;
  text-align: left;
}
.tokenPreviewerContainer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.tokenPreviewerContainer .token {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 2.5rem;
  font-weight: 500;
  text-align: left;
  color: #1677ff;
  width: fit-content;
}

.tokenPreviewerContainer .copyIcon {
  transition: all 0.2s ease-in-out;
}

.tokenPreviewerContainer .token:hover .copyIcon {
  scale: 1.1;
  color: #1677ff !important;
}
