.currentValueContainer {
  display: flex;
  gap: 1rem;
  flex-wrap: nowrap;
  align-items: center;
}
.currentValueContainer .addIcon {
  cursor: pointer;
}

.previousValueContainer {
  display: flex;
  gap: 1rem;
  flex-wrap: nowrap;
  align-items: center;
}

.previousValueContainer .removeIcon {
  cursor: pointer;
}

.previousValueContainer .removeIcon.disabled {
  cursor: not-allowed;
}

.inputContainer.multi {
  display: flex;
  flex-direction: column;
  gap: 0.5rem !important;
}
