#appBar {
  height: 5rem;
  width: 99%;
  position: absolute;
  bottom: 0;
  background-color: #e3e9ee;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  display: flex;

  align-items: center;
}
