html {
  overflow: hidden;
}

html,
body,
#root {
  height: 100%;
}

body {
  overflow: hidden;
  margin: 0;
  font-family: 'Switzer', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.default-module-outlet {
  position: relative;
  height: 100%;
  max-height: calc(100vh - 5rem);
  overflow: hidden;
  transition: all 0.2s ease;
}

@media (min-width: 980px) {
  .default-module-outlet.siderOpen {
    padding-left: 20rem;
    /* width: calc(100% - 20rem); */
  }
}

.ant-input-outlined:hover {
  border-color: #2d85ff;
}
#chatContent .ant-input-outlined:hover {
  border-color: #2d85ff;
}

/* .ant-btn-default:hover:not(.addKnowledgeButton) {
  color: #2d85ff !important;
  border-color: #2d85ff !important;
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

* {
  font-family: 'Switzer', sans-serif !important;
}

*::-webkit-scrollbar {
  width: 8px;
  height: 10px;
  border: 1px solid #ebecee;
  border-radius: 80px;
}
*::-webkit-scrollbar-thumb {
  background: linear-gradient(180deg, #184d7b 0%, #0b2236 100%);
  border-radius: 80px;
}
