.select-input {
  padding: 8px;
  border: 1px solid #ccc;
  cursor: pointer;
  width: 200px;
}

.select-container {
  cursor: pointer;
}

.dropdown-list {
  position: absolute;
  top: var(--dropdown-top, 0px);
  left: var(--dropdown-left, 0px);
}

.dropdown-list.openAbove {
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}

.dropdown-list {
  z-index: 11;
  max-height: 150px;
  border: 1px solid #ccc;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  overflow-y: auto;
  background-color: white;
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.dropdown-list li {
  padding: 8px;
  cursor: pointer;
}

.dropdown-list li.selected {
  background-color: #1777ff !important;
  color: white;
}

.dropdown-list li:hover {
  background-color: #ddd;
}
