.deleteTreeModalContent {
  min-height: 10rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.deleteTreeModalContentText {
  font-weight: 400;
  font-size: 1.1rem;
}

.deleteTreeModalControls {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: space-between;
}
