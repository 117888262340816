#assetDetails {
  height: 100%;
  width: 100%;
}

.documentTabContent {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.manageFilesButtonsContainer {
  width: 100%;
  display: flex;
  justify-content: end;
}

.documentPseudoTable {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  overflow-x: auto;
}
.documentPseudoTable::-webkit-scrollbar {
  display: none;
}

.documentPseudoTableLine {
  min-height: 4rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  min-width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: start;
  flex-wrap: nowrap;
  border-bottom: 2px solid #efefef;
}
.documentPseudoTableLine.header {
  min-height: 3rem;
  align-items: start;
  position: sticky;
  top: 0;
  background: white;
}

.pseudoTableLineCell {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 16vw;
  display: flex;
  align-items: center;
  flex-basis: 33%;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 1.1rem;
  text-align: left;
}
.pseudoTableLineCell.header {
  font-weight: 600;
  font-size: 1.3rem;
  align-items: end;
}
.pseudoTableLineCell.dots {
  justify-content: end;
  position: relative;
}
.manageDots {
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: all 0.2s ease-in-out;
}
.manageDots:hover {
  scale: 1.1;
}
.manageDots:hover svg {
  color: #ff4d4f;
}
.manageFilesButtonsContainer .createFileButton:hover {
  color: white !important;
  background-color: #1777ff !important;
}

.loading-text {
  align-self: center;
  font-size: medium;
}

@media (min-width: 1700px) {
  .pseudoTableLineCell {
    width: 18vw;
  }
}
#assetDetails .generalTab {
  padding-top: 3rem;
}

#assetDetails .UploadDocumentPage {
  padding-top: 3rem;
}
